import React, { Component } from 'react';
import axios from 'axios';
import './Contact.css';

class Contact extends Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        name: '',
        message: '',
      };
      this.handleInputChange = this.handleInputChange.bind(this);
      this.submitForm = this.submitForm.bind(this);
    }

    submitForm(event) {
      event.preventDefault();
      if (!this.state.name.length || !this.state.email || !this.state.message) {
        this.setState({ missingFormData: true });
      } else {
        this.setState({ missingFormData: false, inProgress: true });
      }
      var params = new URLSearchParams();
      params.append('name', this.state.name);
      params.append('email', this.state.email);
      params.append('message', this.state.message);
      axios.post('https://purplesparrowart.com/backend/contact_request.php', params).then((res) => {
        this.setState({ inProgress: false, success: true });
      }).catch(() => {
        this.setState({ inProgress: false, error: true });
      })
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.value;
      const name = target.name;
      var partialState = {};
      partialState[name] = value;
      this.setState(partialState);
    }

    render() {
      if (this.state.success) {
        return (
          <div className="content-page contact">
            <h1>Contact</h1>
            <div style={{ maxWidth: '600px' }}>Thanks for reaching out :) I'll get back to you as soon as possible!</div>
          </div>
        );
      }
      if (this.state.error) {
        return (
          <div className="content-page contact">
            <h1>Contact</h1>
            <div style={{ maxWidth: '600px' }}>
              An error has occured when trying to send your message.
              Please feel free to contact me on <a href="https://www.instagram.com/purple_sparrow_art/" target="_blank">Instagram</a> while I try to get this form fixed.
            </div>
          </div>
        );
      }
      return (
          <div className="content-page contact">
            <h1>Contact</h1>
            <p>If you’d like to contact me please reach out through the form below</p>
            <div>
              <form onSubmit={this.submitForm}>
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleInputChange}
                  />

                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                  />

                  <label htmlFor="message">Comment or Message*</label>
                  <textarea
                    id="message"
                    name="message"
                    style={{ height: '150px' }}
                    value={this.state.message}
                    onChange={this.handleInputChange}
                  ></textarea>

                  { this.state.missingFormData && (
                    <div className="error-message">Please fill out all the fields</div>
                  )}

                  <input type="submit" value="Submit" />
              </form>
            </div>
          </div>
      )
    }
  }

export default Contact;
