import React, { Component } from 'react';
import './Shop.css';
/*globals $:false */
/*globals Instafeed:false */

class Shop extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="content-page shop">
          <h1>Products</h1>
          <p>If you like my art please consider visiting my <a href="https://www.redbubble.com/people/purplesparrow" target="_blank">RedBubble</a>, <a href="https://www.etsy.com/shop/PurpleSparrowArt" target="_blank">Etsy</a> and <a href="https://society6.com/purplesparrow" target="_blank">Society6</a> stores!</p>
          <div className="image-links">
            <a className="tile-link" href="https://www.redbubble.com/people/purplesparrow" target="_blank">
              <img src="https://res.cloudinary.com/purplesparrowart/image/upload/v1610817023/nnkadtf6lbi5cpuasvs2.jpg" />
            </a>
            <a className="tile-link center-image" href="https://www.etsy.com/shop/PurpleSparrowArt" target="_blank">
                <img src="https://res.cloudinary.com/purplesparrowart/image/upload/v1610816857/jdrwofahemepjpwo8mwq.jpg" />
              </a>
            <a className="tile-link" href="https://society6.com/purplesparrow" target="_blank">
              <img src="https://res.cloudinary.com/purplesparrowart/image/upload/v1610816858/obh5qzqfg5mumgfpe5sk.jpg" />
            </a>
          </div>
        </div>
      )
    }
  }

export default Shop;
