import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './About.css';
import './Layout.css';
import InstaFeed from './InstaFeed';

class About extends Component {
    constructor(props) {
        super(props);
    }

    render() {
      return (
        <div className="content-page content-area about">
            <div className="content col-content">
              <h1>About me</h1>
              <p>Hi I’m Agnes, an artist and illustrator behind Purple Sparrow Art.</p>
              <p>Since childhood I loved all things creative. I grew up drawing, painting, sewing, papercrafting, crocheting,
                decoupaging and many more. Now I’m mainly focused on creating watercolour art and vector illustrations and patterns.
                My artwork is mainly inspired by nature and travels. I love creating my art based on the many photos I have taken
                during my travels and adventures.</p>
              <p>If you like my work, feel fee to follow me on <a href="https://www.instagram.com/purple_sparrow_art/" target="_blank">Instagram</a>.</p>
            </div>
            <div className="col-sidebar">
              <img src="https://res.cloudinary.com/purplesparrowart/image/upload/v1610823327/zf4dosvwj8isdebqhwyi.jpg" />
            </div>
        </div>
      )
    }
}

About.propTypes = {
    instaData: PropTypes.shape({}),
}

export default About;
