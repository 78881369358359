const galleries = [
  {
    name: 'Watercolors',
    route: 'watercolors',
    tilefile: 'v1562451172/categories/o0nbwg4dhpeztbjijwib.jpg',
    images: [],
  },
  {
    name: 'Urban sketching',
    route: 'urban_sketching',
    tilefile: 'v1562451172/categories/he9qkuiddrljyh824f4e.jpg',
    images: [],
  },
  {
    name: 'Geometric',
    route: 'geometric',
    tilefile: 'v1562453008/categories/xex8uiilfjb31blcdylp.jpg',
    images: [],
  },
  {
    name: 'Patterns',
    route: 'patterns',
    tilefile: 'v1562453008/categories/t26iiy6gizy3ax32tpp9.jpg',
    images: [],
  },
  {
    name: 'Stickers',
    route: 'stickers',
    tilefile: 'v1562453008/categories/_.jpg',
    images: [],
  },
];

export default {
    galleries,
}
