import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './InstaFeed.css';
import './Layout.css';

export default class InstaFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instaData: props.data
        };
    }

    componentDidMount() {
        this._isMounted = true;
        axios.get(`https://purplesparrowart.com/backend/instagram.php`).then(instaData => {
            if (this._isMounted) {
                this.setState({ instaData: instaData.data });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div className="insta">
                <h1>
                    Instagram <a className="follow-btn" href="https://www.instagram.com/purple_sparrow_art/"><i className="fa fa-instagram" /></a>
                </h1>
                {this.state.instaData ? (
                    <div style={{marginBottom: '30px' }}>
                        {/* <div>
                            <img className="insta-logo" src={this.state.instaData.profile_picture} />
                            <span className="insta-username">{this.state.instaData.username}</span>
                        </div> */}
                        { this.state.instaData.photos ? (
                            <div>
                                { this.state.instaData.photos.map(img => (
                                    <a key={img.url} href={img.link} target='_blank'>
                                        <img className="insta-img" src={img.url}/>
                                    </a>
                                ))}
                            </div>
                        ) : (
                            <div>Failed to load photos</div>
                        )}
                    </div>
                ) : (
                    <div className="text-center" style={{ paddingTop: '30px' }}>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                        <span className="sr-only">Loading...</span>
                    </div>
                )}
            </div>
        );
    }
}


InstaFeed.propTypes = {
    data: PropTypes.shape({}),
}

InstaFeed.defaultProps = {
    data: null,
}