import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import './NavBar.css';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { hoverMenu: null };
  }

  render() {
    return (
        <div className="nav-bar">
          <div className="site-title">
            <Link to="/">
              <h1>Purple Sparrow Art</h1>
              <h2>ARTIST - DESIGNER - PAINTER</h2>
            </Link>
          </div>
          <div className="nav-items">
          <ul>
            <li
              className="portfolio-dropdown-btn"
              onMouseEnter={(e) => this.setState({ hoverMenu: 'portfolio' })}
              onMouseLeave={(e) => this.setState({ hoverMenu: null })}
            >
              <a onTouchStart={() => this.setState({ hoverMenu: this.state.hoverMenu === 'portfolio' ? null : 'portfolio' })}>
                Portfolio <i className="fa fa-angle-down" aria-hidden="true"></i>
              </a>
              <div className={`portfolio-dropdown-content ${this.state.hoverMenu==='portfolio'?'show':'hide'}`} >
                <ul className="inner">
                  { this.props.galleries.map(g => (
                  <Link to={`/gallery/${g.route}`} key={g.route}>
                    <li key={g.name} onClick={(e) => this.setState({ hoverMenu: null })}>
                      {g.name}
                    </li>
                  </Link>
                  ))}
                </ul>
              </div>
            </li>
            <li><Link to="/shop">Shop</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/about">About me</Link></li>
            <li>
              <a className="insta-nav-btn" href="https://www.instagram.com/purple_sparrow_art/" target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
          </div>
        </div>
    );
  }
}

NavBar.propTypes = {
    galleries: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
    ),
};

export default NavBar;
