import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Portfolio.css';
/*globals $:false */

class Portfolio extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className="portfolio">
          {this.props.galleries.map(g => (
            <div className="img-area" key={g.route}>
              <Link to={`/gallery/${g.route}`}>
                <img src={`https://res.cloudinary.com/purplesparrowart/image/upload/c_fill,h_500,q_90,t_fit_500,w_500/${g.tilefile}`} />
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }

export default Portfolio;
