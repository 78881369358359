import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import NavBar from './components/NavBar';
import Gallery from './components/Gallery';
import About from './components/About';
import Shop from './components/Shop';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import data from './data';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      galleries: data.galleries,
    }
  }

  componentDidMount() {
      axios.get(`https://purplesparrowart.com/backend/galleries.php`).then(imagesData => {
          const images = imagesData.data;
          const updatedGalleries = [...this.state.galleries];
          images.forEach(image => {
            const galleryRoute = image.url.split('/')[1];
            const gallery = updatedGalleries.find(g => galleryRoute === g.route);
            if (gallery) {
                gallery.images.push(image);
            }
          });
          this.setState({ galleries: updatedGalleries, loaded: true }, () => {
              axios.get(`https://purplesparrowart.com/backend/instagram.php`).then(instaData => {
                  this.setState({ instaData: instaData.data });
              });
          });
      });
  }


  render() {
    return (
      <Router>
          <NavBar galleries={this.state.galleries} />
          <div className="app-layout">
            <div className="app-content">
              <Route exact path="/" component={Shop} />
              {/* <Route path="/portfolio" render={(props) => <Portfolio galleries={this.state.galleries} />}/> */}
              <Route path="/gallery/:id" render={(props) => <Gallery gallery={this.state.galleries.find(g => g.route === props.match.params.id)} loaded={this.state.loaded} />}/>
              <Route path="/about" render={(props) => <About instaData={this.state.instaData} />}/>
              <Route path="/shop" component={Shop} />
              <Route path="/contact" component={Contact} />
              <Redirect from="*" to="/" />
            </div>
          </div>
      </Router>
    );
  }
}

export default App;
