import React, { Component } from 'react';
import './Gallery.css';
/*globals $:false */

const lightcaseConfig = {
    maxHeight: '1000px',
    swipe: 'true',
};

class Gallery extends Component {
    componentDidMount() {
        $('#myMosaic').Mosaic({
            refitOnResize: true,
            refitOnResizeDelay: true,
            showTailWhenNotEnoughItemsForEvenOneRow: 1,
            maxRowHeightPolicy: 'tail',
            innerGap: 10,
            maxRowHeight: 400,
        });
        $('a[data-rel^=lightcase]').lightcase(lightcaseConfig);
        // document.getElementsByClassName('nav-bar')[0].scrollIntoView({behavior: "smooth"});
    }

    componentDidUpdate(prevProps, prevState) {
            // document.getElementsByClassName('nav-bar')[0].scrollIntoView({behavior: "smooth"});
            $('#myMosaic').Mosaic({
                refitOnResize: true,
                refitOnResizeDelay: true,
                showTailWhenNotEnoughItemsForEvenOneRow: 1,
                maxRowHeightPolicy: 'tail',
                innerGap: 10,
                maxRowHeight: 400,
            });
            $('a[data-rel^=lightcase]').lightcase(lightcaseConfig);
    }

    render() {
    return (
        <div className="gallery">
            <h1>{this.props.gallery.name}</h1>
            {(!this.props.loaded) ? (
                <div style={{marginTop:'80px', color: '#999', fontWeight: '100', letterSpacing: 3, textAlign: 'center'}}>LOADING</div>
            ) : (
                <div id="myMosaic" style={{ minHeight: '500px', paddingBottom: '40px' }}>
                {(this.props.gallery.images.map((img, index) => {
                    const alt = (img.context && img.context.custom) ? img.context.custom.alt : '';
                    return (
                    <a
                        key={`${img.url}_${index}`}
                        data-rel="lightcase:gallery"
                        href=""
                        data-lc-href={`https://res.cloudinary.com/purplesparrowart/image/upload/${img.url}`}
                        title={alt}
                        className="item withImage"
                        data-aspect-ratio={(img.width || 1.0) / (img.height || 1.0)}
                        style={{
                        backgroundColor: 'rgb(230,230,230)',
                        backgroundImage: `url(https://res.cloudinary.com/purplesparrowart/image/upload/t_fit_500/${img.url})`,
                        }}
                    >
                        <div className="overlay" ><div className="texts" style={{  }}>{alt}</div></div>
                    </a>
                    );
                }))}
                </div>
            )}
        </div>
    );
    }
  }

export default Gallery;
